<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Metrics</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col">
          <div class="col-12 col-sm-6">
            <div
              class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0"
            >
              <label for="measureSelect" class="ms-0"
                >Select your preferred measurement system.</label
              >
              <select
                id="measureSelect"
                v-model="data.measure"
                class="form-control"
                default="Metric"
              >
                <option value="1">Metric</option>
                <option value="2">Imperial</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="height" class="ms-0"
            >Enter your height in {{ calcMeasurement }}</label
          >
          <material-input
            id="height"
            v-model="data.height"
            label=""
            variant="dynamic"
          />
          <div v-show="validation.height">Please enter your height</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="weight" class="ms-0"
            >Enter your current weight in {{ calcWeight }}</label
          >
          <material-input
            id="weight"
            v-model="data.weight"
            label=""
            variant="dynamic"
          />
          <div v-show="validation.weight">Please enter your weight</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
            <label for="measureSelect" class="ms-0"
              >Select your activity level from the list of available
              options.</label
            >
            <select
              id="measureSelect"
              v-model="data.activityLevel"
              class="form-control"
              default="Metric"
            >
              <option value="1.2">Light</option>
              <option value="1.375">Moderate</option>
              <option value="1.55">Hight</option>
              <option value="1.75">Extreme</option>
            </select>
            <div v-show="validation.activityLevel">
              Please select your activity level
            </div>
          </div>
          <div class="font-weight-light">
            <br />
            1. LIGHT describes a typical desk job and training less than 3x
            weekly.
            <br />
            2. MODERATE describes 4-5x training per week with some extra low
            level activity.
            <br />
            3. HIGH describes a labor intensive job AND training 5+ days per
            week.
            <br />
            4. EXTREME describes a CrossFit coach who's also training twice per
            day multiple times per week or similar.
            <br />
          </div>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="previousStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="nextStep"
          >Next</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "Metrics",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      data: {
        measure: 1,
        weight: "",
        height: "",
        activityLevel: "",
      },
      validation: {
        height: false,
        weight: false,
        activityLevel: false,
      },
    };
  },
  computed: {
    calcMeasurement() {
      return this.data.measure === "Imperial" ? "inches" : "cm";
    },
    calcWeight() {
      return this.data.measure === "Imperial" ? "lbs" : "kgs";
    },
  },
  methods: {
    previousStep() {
      if (!this.validate()) return;

      this.$emit("metricsupdated", this.data);
      this.$parent.prevStep();
    },
    nextStep() {
      if (!this.validate()) return;

      this.$emit("metricsupdated", this.data);
      this.$parent.nextStep();
    },
    validate() {
      let isValid = true;

      // Validate Height
      if (!this.data.height) {
        isValid = false;
        this.validation.height = true;
      } else {
        this.validation.height = false;
      }

      // Validate Weight
      if (!this.data.weight) {
        isValid = false;
        this.validation.weight = true;
      } else {
        this.validation.weight = false;
      }

      // Validate Activity Level
      if (!this.data.activityLevel) {
        isValid = false;
        this.validation.activityLevel = true;
      } else {
        this.validation.activityLevel = false;
      }

      return isValid;
    },
  },
};
</script>

<style></style>
