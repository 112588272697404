<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Welcome to NutriCoachMe!</h5>
    <br />
    <h6 class="mb-0 text-sm">Let's calculate your starting point</h6>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <p>
          This asks you simple biometric data to produce a pretty reliable
          amount of calories that you should be eat for maintenance.
        </p>
        <p>
          These maintenance calories are a great starting point to follow for
          around two to four weeks to monitor your weight over this time.
        </p>
        <p>
          Depending on your personal goals, from we you can increase your intake
          if you want to increase muscle mass, or decreases your intake if you
          want to reduce body fat.
        </p>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.$parent.nextStep"
          >Continue</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "Welcome",
  components: {
    MaterialButton,
  },
};
</script>
