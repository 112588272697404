<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Goals</h5>
    <div class="multisteps-form__content mt-0">
      <div class="row">
        <div class="col">
          <!-- <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
            <label for="measureSelect" class="ms-0">Select your goal from the list of available options.</label>
            <select class="form-control" id="measureSelect" v-model="data.goal" default="Metric">
              <option value="">Select An Option...</option>
              <option value="0">Weight Loss</option>
              <option value="1">Maintain</option>
              <option value="2">Gain Muscle</option>
            </select>
            <div v-show="validation.goal">Please select your goal</div>
          </div>
          <div class="font-weight-light">
            <div v-if="data.goal === '0'">
              <br>
              Fantastic! You've selected weight loss as your goal.
              <br>
              The aim is to lose 0.5% - 1% of your body weight per week over 6-12 weeks.
              <br>
            </div>
            <div v-else-if="data.goal === '1'">
              <br>
              Fantastic! You've selected maintenance as your goal.
              <br>
              The aim is to maintain your current body weight within 2%.
              <br>
            </div>
            <div v-else-if="data.goal === '2'">
              <br>
              Amazing! You've selected muscle gain as your goal.
              <br>
              We'll be gaining 0.25% - 0.5% of your body weight per week whilst strength training.
              <br>
            </div>
          </div> -->
          <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
            <label for="measureSelect" class="ms-0"
              >Select your dietary style from the list of available
              options.</label
            >
            <select
              id="measureSelect"
              v-model="data.dietaryStyle"
              class="form-control"
              default="Metric"
            >
              <option value="">Select An Option...</option>
              <option value="1">Balanced</option>
              <option value="2">Keto</option>
              <option value="3">Low Carb</option>
              <option value="4">Vegetarian / Vegan</option>
              <option value="5">Low Fat</option>
              <option value="6">High Protein</option>
            </select>
            <div v-show="validation.dietaryStyle">
              Please select your dietary style
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="previousStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="nextStep"
          >Next</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "Goals",
  components: {
    MaterialButton,
  },
  data() {
    return {
      data: {
        goal: "",
        dietaryStyle: "",
      },
      validation: {
        goal: true,
        dietaryStyle: false,
      },
    };
  },
  methods: {
    previousStep() {
      if (!this.validate()) return;

      this.$emit("goalsupdated", this.data);
      this.$parent.prevStep();
    },
    nextStep() {
      if (!this.validate()) return;

      this.$emit("goalsupdated", this.data);
      this.$parent.nextStep();
    },
    validate() {
      let isValid = true;

      // Validate Dietary Style
      if (!this.data.dietaryStyle) {
        isValid = false;
        this.validation.dietaryStyle = true;
      } else {
        this.validation.dietaryStyle = false;
      }

      return isValid;
    },
  },
};
</script>

<style></style>
