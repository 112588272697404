<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content mt-0">
      <div class="row">
        <div class="col">
          <h5 class="font-weight-bolder mt-4 mb-2">Nutrition Overview</h5>
          <div class="nutrition-details">
            <table class="full-width-table">
              <thead>
                <tr>
                  <th>Calories</th>
                  <th>Protein</th>
                  <th>Carbs</th>
                  <th>Fat</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ macros.calories }} kcal</td>
                  <td>{{ macros.protein }} g</td>
                  <td>{{ macros.carbs }} g</td>
                  <td>{{ macros.fats }} g</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="description font-weight-light">
            Your daily calorie intake is {{ macros.calories }} kcal, with
            {{ macros.protein }} g of protein, {{ macros.carbs }} g of
            carbohydrates, and {{ macros.fats }} g of fat.
          </p>

          <p>
            We've carefully tailored your nutrition goals to provide a balanced
            and sustainable approach. The calorie intake of
            {{ macros.calories }} kcal is designed to meet your energy
            requirements, while the distribution of {{ macros.protein }} g of
            protein, {{ macros.carbs }} g of carbohydrates, and
            {{ macros.fats }} g of fat supports your overall well-being and
            fitness goals.
          </p>

          <div class="weight-details">
            <h5 class="font-weight-bolder mt-4 mb-2">Goals</h5>
            <p><strong>Goal Weight:</strong> {{ macros.goalWeight }} kg</p>
            <p>
              Your goal weight of {{ macros.goalWeight }} kg has been set to
              align with health guidelines and your personal preferences.
              Additionally, the rate of change at {{ macros.weightLossRate }} kg
              per week is a gradual and realistic target, promoting a
              sustainable and healthy transformation.
            </p>
          </div>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="previousStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="success"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="nextStep"
          >Accept</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
//import MacrosChartCard from "@/views/dashboards/components/MacrosChartCard.vue";

export default {
  name: "ProgramSummary",
  components: {
    MaterialButton,
  },
  props: {
    macros: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    previousStep() {
      this.$parent.prevStep();
    },
    async nextStep() {
      //warn the user that the macros will be updated.

      const confirm = await this.$swal({
        title: "Are you sure?",
        text:
          "You are about to update your macros. Are you sure you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      });

      if (!confirm) {
        return;
      }

      this.$emit("accept", true);
      this.$parent.nextStep();
    },
  },
};
</script>
<style scoped>
.row {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.col {
  max-width: 800px;
}

.font-weight-bolder {
  color: #007bff;
}

.nutrition-details p,
.weight-details p {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
}

.nutrition-details p:last-child,
.weight-details p:last-child {
  border-bottom: none;
}

.description {
  padding: 20px 0;
  color: #6c757d;
}

.full-width-table {
  width: 100%;
}
</style>
