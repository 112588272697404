<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Let's Setup A Plan</h5>
    <br />
    <h6 class="mb-0 text-sm">Let's calculate your starting point</h6>
    <div class="multisteps-form__content">
      <h6 class="pt-3 text-warning">Warning:</h6>
      <p class="text-warning">
        You are about to change your current macros.. Are you sure you want to
        continue?
      </p>
      <p></p>
      <div v-if="!hasCompletedTwoWeeks">
        <!-- //describe that the user needs to complete the first two weeks of maintnence before creating a plan. -->

        <p>
          It appears that you have yet to complete the inital two weeks of
          maintnence.
        </p>

        <p>
          Please complete the first two weeks of maintnence before creating a
          plan.
        </p>

        <p>
          If you have already completed the first two weeks of maintnence,
          please contact support.
        </p>
      </div>
      <div class="row mt-3"></div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          :disabled="!hasCompletedTwoWeeks"
          @click="continueWithWarning()"
          >Continue</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
//import { completeTwoWeekMaintanence } from "../../../data/plan/plan.js";

export default {
  name: "PlanWelcome",
  components: {
    MaterialButton,
  },
  data() {
    return {
      hasCompletedTwoWeeks: true,
    };
  },
  async mounted() {
    //this.hasCompletedTwoWeeks = await completeTwoWeekMaintanence();
  },
  methods: {
    async continueWithWarning() {
      const confirm = await this.$swal.fire({
        title: "Are you sure?",
        text:
          "You are about to change your current macros.. Are you sure you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5e72e4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue!",
      });

      if (confirm.isConfirmed) {
        this.$parent.nextStep();
      }
    },
  },
};
</script>
