<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Protein Targets</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
          <label for="measureSelect" class="ms-0"
            >Select your preffered protein amount:</label
          >
          <select
            id="measureSelect"
            v-model="data.protein"
            class="form-control"
          >
            <option value="">Select An Option...</option>
            <option value="1.2">Low</option>
            <option value="1.6">Moderate (Recomended)</option>
            <option value="1.8">High</option>
            <option value="2.4">Extra High</option>
          </select>
          <div class="mt-2 font-weight-light">
            <div v-if="data.protein === '1.2'">
              On the lower side of the optimal range
            </div>
            <div v-else-if="data.protein === '1.6'">
              In the middle of the optimal range
            </div>
            <div v-else-if="data.protein === '1.8'">
              On the higher side of the optimal range
            </div>
            <div v-else-if="data.protein === '2.4'">
              On the highest side of the optimal range
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row d-flex mt-4">
      <material-button
        type="button"
        color="light"
        variant="gradient"
        class="js-btn-prev"
        @click="this.$parent.prevStep"
        >Prev</material-button
      >
      <material-button
        type="button"
        color="dark"
        variant="gradient"
        class="ms-auto js-btn-next"
        @click="updateTargets"
        >Next</material-button
      >
    </div>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "SetTargets",
  components: {
    MaterialButton,
  },
  data() {
    return {
      data: {
        protein: "",
      },
    };
  },
  methods: {
    updateTargets() {
      this.$emit("targetsupdated", this.data);
      this.$parent.nextStep();
    },
  },
};
</script>
