<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-info shadow-info border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Welcome"
                        :class="activeStep >= 0 ? activeClass : ''"
                        @click="activeStep = 0"
                      >
                        <span>Welcome</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Goals"
                        :class="activeStep >= 1 ? activeClass : ''"
                        @click="activeStep = 1"
                      >
                        <span>Goals</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Diet"
                        :class="activeStep >= 2 ? activeClass : ''"
                        @click="activeStep = 2"
                      >
                        Diet
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Target"
                        :class="activeStep >= 3 ? activeClass : ''"
                        @click="activeStep = 3"
                      >
                        Target
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Summary"
                        :class="activeStep >= 4 ? activeClass : ''"
                        @click="activeStep = 4"
                      >
                        Summary
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <welcome :class="activeStep === 0 ? activeClass : ''" />
                    <set-goal
                      :current-average-weight="parseFloat(currentAverageWeight)"
                      :class="activeStep === 1 ? activeClass : ''"
                      @goalsupdated="goalsUpdated"
                    />
                    <set-diet
                      :class="activeStep === 2 ? activeClass : ''"
                      @dietupdated="dietUpdated"
                    />
                    <set-targets
                      :class="activeStep === 3 ? activeClass : ''"
                      @targetsupdated="targetsUpdated"
                    />
                    <program-summary
                      :macros="updatedMacros"
                      :class="activeStep === 4 ? activeClass : ''"
                      @accept="accept"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Welcome from "../plan/components/Welcome.vue";
import SetTargets from "./components/SetTargets.vue";
import SetGoal from "./components/SetGoal.vue";
import SetDiet from "./components/SetDiet.vue";
import ProgramSummary from "./components/ProgramSummary.vue";
import {
  getAverageWeight,
  getCurrentMacros,
  createNewPlan,
  calculateNewMacros,
} from "../../data/plan/plan.js";
import { logError } from "../../errors/rollbar";

export default {
  name: "PlanSetup",
  components: {
    Welcome,
    SetTargets,
    SetGoal,
    SetDiet,
    ProgramSummary,
  },
  data() {
    return {
      currentAverageWeight: "0",
      goals: {
        goal: "", //0 == weightloss, 1 == maintanence 2 == weightgain
        targetWeight: "",
        targetRate: "",
      },
      targets: {
        protein: "",
      },
      diet: {
        dietaryStyle: "",
      },
      currentMacros: {
        calories: "",
        protein: "",
        carbs: "",
        fat: "",
      },
      updatedMacros: {
        calories: "",
        protein: "",
        carbs: "",
        fat: "",
        goalWeight: "",
        weightLossRate: "",
      },
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 4,
    };
  },
  async created() {
    await this.getAverageWeight();
    this.currentMacros = await getCurrentMacros();
    console.log(this.currentMacros);
  },
  methods: {
    async nextStep() {
      if (this.activeStep <= this.formSteps) {
        this.activeStep += 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async targetsUpdated(data) {
      console.log("updating the targets now.", data);
      this.targets = data;

      try {
        const { calories, protein, carbs, fats } = await calculateNewMacros(
          this.goals.targetRate,
          this.goals.goal,
          this.diet.dietaryStyle,
          this.currentAverageWeight,
          this.targets.protein
        );

        this.updatedMacros = {
          calories: calories,
          protein,
          carbs,
          fats,
          goalWeight: this.goals.targetWeight,
          weightLossRate: this.goals.targetRate,
        };
      } catch (error) {
        logError(error);
      }
    },
    dietUpdated(data) {
      this.diet = data;
    },
    goalsUpdated(data) {
      this.goals = data;
    },
    async accept(value) {
      if (value) {
        await createNewPlan(
          this.currentAverageWeight,
          this.goals.goal,
          this.goals.targetWeight,
          this.goals.targetRate,
          this.targets.protein,
          this.diet.dietaryStyle,
          this.currentMacros.calories
        );

        this.$swal.fire({
          title: "Success!",
          text: "Your plan has been created!",
          icon: "success",
          confirmButtonText: "Ok",
        });

        this.$router.push({ name: "Macro Calculator" });
      }
    },
    async getAverageWeight() {
      const response = await getAverageWeight();
      this.currentAverageWeight = response;
    },
  },
};
</script>
