<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Select Your Goal</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3"></div>
      <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
        <label class="font-weight-bolder" for="measureSelect"
          >Select your goal from the list of available options:</label
        >
        <select
          id="measureSelect"
          v-model="data.goal"
          class="form-control"
          default="Metric"
          @change="updateTargetWeight"
        >
          <option value="">Select your goal</option>
          <option value="0">Weight Loss</option>
          <option value="1">Maintain</option>
          <option value="2">Gain Muscle</option>
        </select>
      </div>
      <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
        <label class="font-weight-bolder" for="targetSelect"
          >Select Target Weight:</label
        >
        <input
          id="targetSelect"
          v-model="data.targetWeight"
          type="number"
          class="form-control"
          placeholder="Select Target Weight"
        />
      </div>
      <div v-if="data.goal">
        <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
          <label class="font-weight-bolder" for="rateSelect"
            >Select Target Rate:</label
          >
          <input
            id="rateSelect"
            v-model="data.targetRate"
            type="range"
            class="form-control range-control"
            min="0"
            max="1"
            step="0.25"
            required
          />
          <div class="row">
            <div>{{ targetRate }}</div>
            <div>{{ targetWeight }}</div>
            <div>Difficulty: {{ difficulty }}</div>
          </div>
        </div>
        <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
          <div class="row">
            <div><strong>Estimated End:</strong></div>
            <div>{{ endDate }}</div>
          </div>
        </div>
      </div>

      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="updateGoals"
          >Next</material-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "SetGoal",
  components: {
    MaterialButton,
  },
  props: {
    currentAverageWeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: {
        goal: "", //0 == weightloss, 1 == maintanence 2 == weightgain
        targetWeight: "",
        targetRate: 0.25,
        weeklyChange: "",
      },
    };
  },
  computed: {
    targetRate() {
      const rate = this.data.targetRate;

      if (rate === 0) return "Maintain";

      if (rate <= 0.25) return `${rate} % per week (Highly recommended)`;

      if (rate <= 0.5) return `${rate} % per week (Recommended)`;

      if (rate <= 0.75) return `${rate} % per week (Requires a lot of effort)`;

      if (rate <= 1) return `${rate} % per week (Requires extreme effort)`;
      else return "None";
    },
    ratePerWeek() {
      const rate = this.data.targetRate;

      if (rate === 0) return "Maintain";

      let ratePerWeek = this.currentAverageWeight * (rate / 100);
      return Math.round(ratePerWeek * 100) / 100;
    },
    targetWeight() {
      const rate = this.ratePerWeek;
      return `${rate} kg per week`;
    },
    endDate() {
      const rate = this.data.targetRate;

      if (rate === 0) return "Maintain";

      let ratePerWeek = this.currentAverageWeight * (rate / 100);

      const weeks = Math.round(
        (this.currentAverageWeight - this.data.targetWeight) / ratePerWeek
      );

      const today = new Date();
      const endDate = new Date(today.setDate(today.getDate() + weeks * 7));

      return endDate.toDateString() + " (" + weeks + " weeks approx)";
    },
    difficulty() {
      const rate = this.data.targetRate;

      if (rate === 0) return "Maintain";

      if (rate <= 0.25) return "Easy";

      if (rate <= 0.5) return "Medium - Requires Meal Planning";

      if (rate <= 0.75)
        return "Hard - Requires Strict Meal Planning And Exercise";

      if (rate <= 1)
        return "Extreme - Requires Strict Meal Planning And Strict Exercise";
      else return "None";
    },
  },
  mounted() {
    console.log("Average Weight In Goal Prop", this.currentAverageWeight);
  },
  methods: {
    updateGoals() {
      this.data.weeklyChange = this.ratePerWeek;
      this.$emit("goalsupdated", this.data);
      this.$parent.nextStep();
    },
    updateTargetWeight() {
      if (this.data.goal === "0") {
        this.data.targetWeight =
          this.currentAverageWeight - this.currentAverageWeight * 0.075;
        this.data.targetWeight = this.data.targetWeight.toFixed(2);
        this.data.targetRate = 0.25;
      } else if (this.data.goal === "2") {
        this.data.targetWeight =
          this.currentAverageWeight + this.currentAverageWeight * 0.05;
        this.data.targetRate = 0.25;
        this.data.targetWeight = this.data.targetWeight.toFixed(2);
      } else {
        this.data.targetWeight = this.currentAverageWeight;
        this.data.targetRate = 0;
      }
    },
  },
};
</script>
<style scoped>
.range-control {
  appearance: auto;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
</style>
