<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-info shadow-info border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Welcome"
                        :class="activeStep >= 0 ? activeClass : ''"
                        @click="activeStep = 0"
                      >
                        <span>Welcome</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="User Info"
                        :class="activeStep >= 1 ? activeClass : ''"
                        @click="activeStep = 1"
                      >
                        <span>Information</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Metrics"
                        :class="activeStep >= 2 ? activeClass : ''"
                        @click="activeStep = 2"
                      >
                        Metrics
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Goals"
                        :class="activeStep >= 3 ? activeClass : ''"
                        @click="activeStep = 3"
                      >
                        Goals
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Finish"
                        :class="activeStep >= 4 ? activeClass : ''"
                        @click="activeStep = 4"
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <welcome :class="activeStep === 0 ? activeClass : ''" />
                    <information
                      :user-info="userInfo"
                      :class="activeStep === 1 ? activeClass : ''"
                      @userupdated="userUpdated"
                    />
                    <metrics
                      :metrics-info="metricsInfo"
                      :class="activeStep === 2 ? activeClass : ''"
                      @metricsupdated="metricsUpdated"
                    />
                    <goals
                      :goals-info="goalsInfo"
                      :class="activeStep === 3 ? activeClass : ''"
                      @goalsupdated="goalsUpdated"
                    />
                    <macros
                      :calories="macrosInfo.calories"
                      :protein="macrosInfo.protein"
                      :carbs="macrosInfo.carbs"
                      :fats="macrosInfo.fats"
                      :macros="macrosInfo"
                      :class="activeStep === 4 ? activeClass : ''"
                      @macrosupdated="macrosUpdated"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Welcome from "./components/Welcome.vue";
import Information from "./components/Information.vue";
import Metrics from "./components/Metrics.vue";
import Goals from "./components/Goals.vue";
import Macros from "./components/Macros.vue";
import {
  calculateTDEE,
  calculateDietaryNutrition,
} from "../../helpers/nutritionCalculations.js";
import Nutrition from "../../data/nutrition/nutrition.js";
import UserSettings from "@/data/user/userSettings";
import { mapActions } from "vuex";
import { logError } from "../../errors/rollbar.js";

export default {
  name: "NewUser",
  components: {
    Welcome,
    Information,
    Metrics,
    Goals,
    Macros,
  },
  data() {
    return {
      userInfo: {
        firstName: "",
        lastName: "",
        age: "",
        gender: "",
      },
      metricsInfo: {
        measure: "",
        height: "",
        weight: "",
        activityLevel: "",
      },
      goalsInfo: {
        goal: "",
        dietaryStyle: "",
      },
      macrosInfo: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fats: 0,
      },
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 4,
    };
  },
  methods: {
    ...mapActions(["setName", "onboardingComplete"]),
    async nextStep() {
      if (this.activeStep === 3) {
        this.calculateNutrition();
      }

      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        await this.setName(this.userInfo.firstName);
        await this.onboardingComplete(true);

        const { error: saveUserError } = await UserSettings.updateUserInfo(
          this.userInfo.firstName,
          this.metricsInfo.measure,
          this.userInfo.gender,
          this.metricsInfo.activityLevel,
          this.userInfo.age,
          this.metricsInfo.height,
          this.metricsInfo.weight
        );

        if (saveUserError) {
          logError(saveUserError);
          alert("Something went wrong. Please try again.");
          return;
        }

        const { error } = await Nutrition.saveMacros(
          await UserSettings.getUserId(),
          this.macrosInfo.calories,
          this.macrosInfo.protein,
          this.macrosInfo.carbs,
          this.macrosInfo.fats,
          this.goalsInfo.dietaryStyle,
          parseInt(this.goalsInfo.goal),
          this.metricsInfo.weight
        );

        if (error) {
          logError(error);
          alert("Something went wrong. Please try again.");
        } else {
          this.$router.push({ name: "Macro Calculator" });
        }
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    calculateNutrition() {
      const tdee = calculateTDEE(
        this.userInfo.gender,
        this.userInfo.age,
        this.metricsInfo.weight,
        this.metricsInfo.height,
        this.metricsInfo.activityLevel,
        this.metricsInfo.measure
      );
      const { protein, carbs, fat } = calculateDietaryNutrition(
        this.goalsInfo.dietaryStyle,
        tdee
      );

      this.macrosInfo.calories = tdee;
      this.macrosInfo.protein = protein;
      this.macrosInfo.carbs = carbs;
      this.macrosInfo.fats = fat;
    },
    userUpdated(userInfo) {
      this.userInfo = userInfo;
    },
    metricsUpdated(metricsInfo) {
      this.metricsInfo = metricsInfo;
    },
    goalsUpdated(goalsInfo) {
      this.goalsInfo = goalsInfo;
    },
    macrosUpdated(macrosInfo) {
      this.macrosInfo = macrosInfo;
      console.log(macrosInfo);
    },
  },
};
</script>
