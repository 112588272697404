<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mt-4 mb-2">Important Information:</h5>
    <p class="text-muted">
      For the first two weeks, we're focusing on maintenance to help you find
      your Total Daily Energy Expenditure (TDEE) and get familiar with the app.
      This allows you to ease into the experience without the immediate pressure
      of weight loss goals.
    </p>

    <h5 class="font-weight-bolder mt-4 mb-2">
      Here are your calculated macros:
    </h5>

    <div class="multisteps-form__content mt-0">
      <div class="row">
        <div class="col">
          <div>Calories:</div>
          <div>{{ calories }}</div>
        </div>
        <div class="col">
          <div>Protein:</div>
          <div>{{ protein }}</div>
        </div>
        <div class="col">
          <div>Carbs:</div>
          <div>{{ carbs }}</div>
        </div>
        <div class="col">
          <div>Fats:</div>
          <div>{{ fats }}</div>
        </div>
        <!-- <div v-show="calories > 0" class="mt-4 col-lg-0 col-sm-0 mt-sm-3">
            <macros-chart-card :prop="macros" />
          </div> -->
      </div>
      <h6 class="font-weight-bolder mt-4 mb-2">
        Want to set your own custom macros?
      </h6>
      <button
        type="button"
        class="btn btn-sm btn-outline-info"
        @click="customValues = true"
      >
        Yes
      </button>
      <div v-if="customValues" class="row">
        <div class="col">
          <div>Calories:</div>
          <div>{{ calculatedCalories }}</div>
        </div>
        <div class="col">
          <div>Protein:</div>
          <input
            v-model="customMacros.protein"
            class="input-group"
            type="number"
          />
        </div>
        <div class="col">
          <div>Carbs:</div>
          <input
            v-model="customMacros.carbs"
            class="input-group"
            type="number"
          />
        </div>
        <div class="col">
          <div>Fats:</div>
          <input
            v-model="customMacros.fats"
            class="input-group"
            type="number"
          />
        </div>
        <!-- <div v-show="calories > 0" class="mt-4 col-lg-0 col-sm-0 mt-sm-3">
            <macros-chart-card :prop="macros" />
          </div> -->
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="previousStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="nextStep"
          >Accept</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
//import MacrosChartCard from "@/views/dashboards/components/MacrosChartCard.vue";

export default {
  name: "Macros",
  components: {
    //MacrosChartCard,
    MaterialButton,
  },
  props: {
    macros: {
      type: Object,
      required: true,
    },
    calories: {
      type: Number,
      required: true,
    },
    protein: {
      type: Number,
      required: true,
    },
    carbs: {
      type: Number,
      required: true,
    },
    fats: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      customMacros: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fats: 0,
      },
      customValues: false,
    };
  },
  computed: {
    calculatedCalories() {
      return (
        this.customMacros.carbs * 4 +
        this.customMacros.protein * 4 +
        this.customMacros.fats * 9
      );
    },
  },
  mounted() {
    this.customMacros = this.macros;
  },
  methods: {
    previousStep() {
      this.$parent.prevStep();
    },
    nextStep() {
      this.customMacros.calories = this.calculatedCalories;
      this.$emit("macrosupdated", this.customMacros);
      this.$parent.nextStep();
    },
  },
};
</script>
