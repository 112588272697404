<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Diet</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3"></div>

      <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
        <label for="measureSelect" class="ms-0"
          >Select your dietary style from the list of available options.</label
        >
        <select
          id="measureSelect"
          v-model="data.dietaryStyle"
          class="form-control"
          default="Metric"
        >
          <option value="">Select An Option...</option>
          <option value="1">Balanced</option>
          <option value="2">Keto</option>
          <option value="3">Low Carb</option>
          <option value="4">Vegetarian / Vegan</option>
          <option value="5">Low Fat</option>
          <option value="6">High Protein</option>
        </select>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="updateDiet"
          >Next</material-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "SetDiet",
  components: {
    MaterialButton,
  },
  data() {
    return {
      data: {
        dietaryStyle: "",
      },
    };
  },
  methods: {
    updateDiet() {
      this.$emit("dietupdated", this.data);
      this.$parent.nextStep();
    },
  },
};
</script>
