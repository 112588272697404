<template>
  <navbar />
  <div
    class="page-header bg-gradient-info position-relative m-3 border-radius-xl min-vh-35"
  >
    <span class="mask bg-gradient-dark opacity-2"></span>
    <div class="container py-5 postion-relative z-index-2">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <h2 class="text-white">Pick the best plan for you</h2>
          <p class="text-white">
            Choose a plan that fits your nutrition coaching needs. All plans come with personalized meal plans, progress tracking, and expert support.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid px-4 my-5">
    <div class="card mt-n8">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-4 col-md-6 col-7 mx-auto text-center">
            <div class="nav-wrapper mt-5 position-relative z-index-2">
              <ul
                id="tabs-pricing"
                class="nav nav-pills nav-fill flex-row p-1"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    id="tabs-iconpricing-tab-1"
                    class="nav-link mb-0"
                    :class="{ active: activeTab === 'monthly' }"
                    data-bs-toggle="tab"
                    href="#monthly"
                    role="tab"
                    aria-controls="monthly"
                    aria-selected="true"
                    >Monthly</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tabs-iconpricing-tab-2"
                    class="nav-link mb-0"
                    :class="{ active: activeTab === 'yearly' }"
                    data-bs-toggle="tab"
                    href="#annual"
                    role="tab"
                    aria-controls="annual"
                    aria-selected="false"
                    >Annual</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tab-content tab-space">
          <div id="monthly" class="tab-pane" :class="{ active: activeTab === 'monthly' }">
            <div>
              <pricing-card
                :badge="{ color: 'success', label: 'Monthly' }"
                :price="{ currency: '$', value: '15', charged: 'mo' }"
                :specifications="[
                  { label: 'Personalized nutrition plan', includes: true },
                  { label: 'Weekly progress reports', includes: true },
                  { label: 'Email / Chat support', includes: true },
                  { label: 'Access to AI food analysis', includes: false },
                  { label: 'One-on-one coaching sessions', includes: false },
                  { label: 'Personalised nutrition coach', includes: false },
                ]"
                :action="{
                  color: 'dark',
                  route: monthlyPaymentLink,
                  label: 'Join',
                }"
              />
            </div>
          </div>
          <div id="annual" class="tab-pane">
            <div class="row">
              <pricing-card
                :badge="{ color: 'success', label: 'Yearly' }"
                :price="{ currency: '$', value: '90', charged: 'year', discountValue: '180', discountCharged: 'year' }"
                :specifications="[
                  { label: 'Personalized nutrition plan', includes: true },
                  { label: 'Weekly progress reports', includes: true },
                  { label: 'Email / Chat support', includes: true },
                  { label: 'Access to AI food analysis', includes: true },
                  { label: 'One-on-one onboarding', includes: true },
                  { label: 'Personalised nutrition coach', includes: true },
                ]"
                :action="{
                  color: 'dark',
                  route: monthlyPaymentLink,
                  label: 'Join',
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container my-6">
        <div class="row">
          <div class="mx-auto text-center col-12">
            <h4 class="opacity-5">Proudly Operated By</h4>
          </div>
        </div>
        <div class="mt-4 bg-gradient-info border-radius-lg p-2 opacity-6 logo-trusted">
          <div>
            <img
              class="p-2"
              src="../../assets/images/fatforweightloss.svg"
              alt="fatforweightloss"
            />
          </div>
        </div>
        <div class="mt-6 row">
          <div class="mx-auto text-center col-md-6">
            <h2>Frequently Asked Questions</h2>
            <p>
              Here are some common questions about our nutrition coaching app.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="mx-auto col-md-10 text-balance">
            <div id="accordionRental" class="accordion">
              <accordion-item
                accordion-id="headingOne"
                collapse-id="collapseOne"
                active
              >
                <template #question>How do I get started?</template>
                <template #answer>
                  Getting started is easy! Simply choose a plan that fits your needs, sign up, and you'll have immediate access to personalized nutrition plan and progress tracking.
                </template>
              </accordion-item>
              <accordion-item
                accordion-id="headingTwo"
                collapse-id="collapseTwo"
              >
                <template #question>What payment methods do you accept?</template>
                <template #answer>
                  We accept all major credit cards, including Visa, MasterCard, and American Express. You can also pay via PayPal, Apple Pay and Google Pay.
                </template>
              </accordion-item>

              <accordion-item
                accordion-id="headingThree"
                collapse-id="collapseThree"
              >
                <template #question>How often will I receive updates?</template>
                <template #answer>
                  You will receive weekly updates with new nutrition tips, nutritional changes and progress tracking. The yearly plans also include a personalised coach to help you every step of the way.
                </template>
              </accordion-item>

              <accordion-item
                accordion-id="headingFour"
                collapse-id="collapseFour"
              >
                <template #question>Can I cancel my subscription?</template>
                <template #answer>
                  Yes, you can cancel your subscription at any time. If you cancel, you will continue to have access to your plan until the end of your billing period.
                </template>
              </accordion-item>

              <accordion-item
                accordion-id="headingFifth"
                collapse-id="collapseFifth"
              >
                <template #question>Do you offer a free trial?</template>
                <template #answer>
                  Yes, we offer a 3-day free trial for our yearly subscription. You can sign up and try out all the features before committing to a subscription.
                </template>
              </accordion-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import PricingCard from "./components/PricingCard.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import AccordionItem from "./components/AccordionItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";

import { mapState, mapMutations } from "vuex";
export default {
  name: "Pricing",
  components: {
    Navbar,
    PricingCard,
    AppFooter,
    AccordionItem,
  },
  data() {
    return {
        activeTab: 'monthly',
        monthlyPaymentLink: '',
        annualPaymentLink: ''
    };
  },
  computed: {
    ...mapState(["isPinned"]),
  },
  mounted() {
    this.toggleEveryDisplay();
    setNavPills();
  },
  beforeMount() {
    if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "dev.nutricoachme.com" ||
        window.location.hostname === "nutricoachmestripe.aaroseday.com.au"
    ) {
      this.monthlyPaymentLink = process.env.VUE_APP_TEST_PAY_MONTHLY_LINK;
      this.annualPaymentLink = process.env.VUE_APP_TEST_PAY_LINK;
    } else {
      this.monthlyPaymentLink = process.env.VUE_APP_PAY_MONTHLY_LINK;
      this.annualPaymentLink = process.env.VUE_APP_PAY_LINK;
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    if (this.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.setIsPinned(true);
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "setIsPinned"]),
  },
};
</script>
<style lang="scss" scoped>

.text-balance {
  text-wrap: balance;
}

.logo-trusted {
  margin: 0 auto;
  max-width: 300px;
}

</style>